@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Bai+Jamjuree:400,400i,700);
* {
  font-family: 'Bai Jamjuree', sans-serif; }

.max-width {
  width: 100%;
  max-width: 1260px; }

* {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #000;
  text-decoration: none; }

a {
  cursor: pointer; }

html, body {
  width: 100%;
  height: 100%; }

#app {
  width: 100%;
  height: 100%; }

button {
  cursor: pointer;
  border: none;
  background: none; }

.main-container {
  width: calc(100% - 100px);
  height: 100%;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center; }

.main-maxwidth {
  max-width: 1024px;
  width: 100%;
  margin-top: 50px;
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between; }
  @media (min-width: 720px) {
    .main-maxwidth {
      margin-top: 100px;
      height: calc(100% - 200px); } }

.main-topbar {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.main-body {
  height: calc(100% - 100px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }

.main-bottom {
  height: 50px; }

.img-x-logo {
  width: 15px;
  cursor: pointer; }

.img-c-logo {
  width: 142px; }
  @media (min-width: 720px) {
    .img-c-logo {
      width: 200px; } }

.main-title {
  font-size: 24px;
  font-weight: bold;
  color: #FFF; }
  @media (min-width: 720px) {
    .main-title {
      font-size: 68px; } }

